import React from 'react';
import ResourceList from '../../components/ResourceList';

import Scoop_chart_pdf_uk from '../../pdfs/nutricia-product-scoop-chart-2024-august-uk.pdf';
import Scoop_chart_pdf_roi from '../../pdfs/nutricia-product-scoop-chart-2024-august-ire.pdf';

const adultResources = [
    {
        id: "allergen_list",
        compendium_cta: "Adult allergen list",
        downloads: [
            {
                label: "GMS Codes - Adult Products",
                url: "https://www.nutricia.ie/content/dam/sn/local/irl/hcp/pdf/adult-gms-codes-updated.pdf"
            },
            {
                label: "GMS Codes - Metabolic & Ketogenic Products",
                url: "https://www.nutricia.ie/content/dam/sn/local/irl/hcp/pdf/mets-keto-gms-codes-updated.pdf"
            },
            {
                label: "GMS Codes - Full Nutricia Range",
                url: "https://www.nutricia.ie/content/dam/sn/local/irl/hcp/pdf/full-product-range-gms-codes-updated.pdf"
            },
            {
                label: "PENG guidelines",
                url: "https://www.nutricia.com/content/dam/sn/local/gbr/approved/pdf/peng-nutricia-combined-pocket-guide.pdf"
            },
            {
                label: "References",
                url: "https://www.nutricia.com/content/dam/sn/local/gbr/approved/pdf/new-dietetic-app-reference-list.pdf"
            },
            {
                label: "Product scoop chart",
                url: Scoop_chart_pdf_uk,
                country: "uk",
            },
            {
                label: "Product scoop chart",
                url: Scoop_chart_pdf_roi,
                country: "roi",
            },
            {
                label: "Flocare compendium",
                url: "https://www.nutricia.co.uk/content/dam/sn/hcp/gbr/nutriciahcp/flocare-compendium-2022-10-update.pdf"
            },
            {
                label: "Nutritionally complete volumes",
                url: "https://www.nutricia.co.uk/hcp/resource-centre/product-range-nutritionally-complete-values.html",
                country: "uk",
            },
            {
                label: "Nutritionally complete volumes",
                url: "https://www.nutricia.ie/hcp/resource-centre/product-range-nutritionally-complete-values.html",
                country: "roi",
            },
        ]
    },
];

const paediatricsResources = [
    {
        id: "allergen_list",
        compendium_cta: "Paediatrics allergen list",
        downloads: [
            {
                label: "GMS Codes - Paediatric Products",
                url: "https://www.nutricia.ie/content/dam/sn/local/irl/hcp/pdf/paediatric-gms-codes-updated.pdf"
            },
            {
                label: "GMS Codes - Metabolic & Ketogenic Products",
                url: "https://www.nutricia.ie/content/dam/sn/local/irl/hcp/pdf/mets-keto-gms-codes-updated.pdf"
            },
            {
                label: "GMS Codes - Full Nutricia Range",
                url: "https://www.nutricia.ie/content/dam/sn/local/irl/hcp/pdf/full-product-range-gms-codes-updated.pdf"
            },
            {
                label: "Neocate LCP Dilutions Guidance",
                url: "https://smartmedia.digital4danone.com/is/content/danonecs/Neocate_LCP_Concentration_Chart_2024pdf",
            },
            {
                label: "Neocate Range - Monitoring Micronutrients Status",
                url: "https://www.milupa.ch/content/dam/dam/amn/local/gb/approved/pdf/neocate-monitoringmicronutrientstatus.pdf",
            },
            {
                label: "PENG guidelines",
                url: "https://www.nutricia.com/content/dam/sn/local/gbr/approved/pdf/peng-nutricia-combined-pocket-guide.pdf"
            },
            {
                label: "References",
                url: "https://www.nutricia.com/content/dam/sn/local/gbr/approved/pdf/new-dietetic-app-reference-list.pdf"
            },
            {
                label: "Product scoop chart",
                url: "https://smartmedia.digital4danone.com/is/content/danonecs/nutricia-product-scoop-chart-aug-2024-ukpdf",
                country: "uk",
            },
            {
                label: "Product scoop chart",
                url: "https://smartmedia.digital4danone.com/is/content/danonecs/nutricia-product-scoop-chart-aug-2024-irepdf",
                country: "roi",
            },
            {
                label: "Flocare compendium",
                url: "https://www.nutricia.co.uk/content/dam/sn/hcp/gbr/nutriciahcp/flocare-compendium-2022-10-update.pdf"
            },
        ]
    }
];

const Resources = [
    {
        "id": "adults",
        "title": "Adults",
        "component": <ResourceList items={adultResources} />,
    },
    {
        "id": "paediatrics",
        "title": "Paediatrics",
        "component": <ResourceList items={paediatricsResources} />,
    },
];

export default Resources;
